import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Type } from "../../components/ui/Typography";
import PageHeader from "../../components/ui/PageHeader";
import DipraButton from "../../components/ui/DipraButton";
import { colors } from "../../vars/palette";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { Img, WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 1rem 0;
  a {
    &:hover {
      color: ${colors.white.hex};
      background-color: ${colors.blue.hex};
    }
  }
`;

const ImageContainer = styled(Container)`
  margin: 4rem 0%;

  .col {
    padding: 0 5rem;
  }
`;

// const JoinMailingListButton = () => {
//   return (
//     <ButtonWrap>
//       <DipraButton
//         link={{
//           to: "/news/dipra-e-newsletters/subscribe",
//           target: "_self",
//           text: "JOIN OUR MAILING LIST"
//         }}
//         color={color ? color.color : colors.darkBlue.hex}
//         textColor={colors.white.hex}
//       />
//     </ButtonWrap>
//   );
// };

const ENewslettersNewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      dailyNewsImage: contentfulAsset(
        title: { eq: "DIPRANews Example" }
      ) {
        ...NodeImageFields
      }
      thinkBeyondPvcImgage: contentfulAsset(
        title: { eq: "Dipra Minute - April Think beyond PVC image" }
      ) {
        ...NodeImageFields
      }
    }
  `);
  return (
    <>
      <PageHeader
        headline={`Join Our Mailing List`}
        // subhead={`DIPRA keeps you up-to-date on issues important to the water industry.`}
        subtext={`DIPRA provides two monthly e-newsletters to keep you up-to-date on information covering what is happening within the Ductile community and beyond.`}
      />
      <ButtonWrap>
        <DipraButton
          link={{
            to: "/news/dipra-e-newsletters/subscribe",
            target: "_self",
            text: "PLEASE SUBSCRIBE NOW!"
          }}
          color={colors.techNavGreen.hex}
          textColor={colors.white.hex}
        />
      </ButtonWrap>
      <ImageContainer className="image-container" fluid>
        <Row>
          <Col>
            <WmkLink to="/docs/dipra-minute-april-2024-think-beyond-pvc">
              <WmkImage image={new Img(data?.thinkBeyondPvcImgage)} />
            </WmkLink>
          </Col>
          <Col>
            <WmkLink to="/docs/dipra-daily-news-april-6-2023">
              <WmkImage image={new Img(data?.dailyNewsImage)} />
            </WmkLink>
          </Col>
        </Row>
      </ImageContainer>

      <Type.Body style={{ textAlign: "center" }}>
        E-newsletter Examples
      </Type.Body>
      <ButtonWrap>
        <DipraButton
          link={{
            to: "/news/dipra-minute/archives",
            target: "_self",
            text: "DIPRA MINUTE ARCHIVES"
          }}
          color={colors.techNavGreen.hex}
          textColor={colors.white.hex}
        />
      </ButtonWrap>
      <div style={{ paddingBottom: "3rem" }} />
    </>
  );
};

export default ENewslettersNewsPage;
