import { graphql } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Resource } from "../classes/Resource";
import TechResContent from "../components/ui/TechnicalResources/Content/TechResContent";
// import get from "lodash/get";
import { colors } from "../vars/palette";
import styled from "styled-components";
import { BREAKPOINT_MEDIUM_MAX, Type } from "../components/ui/Typography";
import FixedNav from "../components/ui/TechnicalResources/Nav/FIxedNav";
import TechButtons from "../components/ui/TechnicalResources/Buttons/TechButtons";
import CalcThrustRestraint from "../components/calculators/kind/Thrust";
import CalcSupports from "../components/calculators/kind/Supports";
import CalcHydraulic from "../components/calculators/kind/Hydraulic";
import CalcThicknessDesign from "../components/calculators/kind/Thickness";
import CalcLcca from "../components/calculators/kind/LCCA/LCCA";
import TechResNav from "../components/ui/TechnicalResources/Nav/TechResNav";
import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import { WmkSeo } from "wmk-seo";
import { ResourceQuery } from "../fragments/NodeResourceFields";
import { RolloverMenuFields } from "../fragments/NodeMenuFields";
import NewsAndCommentaryPageV2, {
  JoinMailingListButton
} from "../components/ui/News/NewsAndCommentaryPage/NewsAndCommentaryPage";
import PageHeader from "../components/ui/PageHeader";
import DipraNewsSlider from "../components/ui/News/NewsSliders/DipraNewsSlider";
import EventList from "../components/ui/Events/Events";
import NewsroomEventsList from "../components/ui/Events/NewsroomEventsList";
import { WmkLink } from "wmk-link";
import ENewslettersNewsPage from "../routes/MediaNewsroom/ENewslettersNewsPage";
import MediaContacts from "../routes/MediaNewsroom/MediaContacts";
import StaffContent from "../routes/About/Staff/Staff";
import StaffNewsroomComp from "../routes/MediaNewsroom/StaffNewsroom";
import DocumentTable from "../components/ui/DocumentTable";
import MemberCompaniesContent from "../routes/About/MemberCompanies/MemberCompanies";
// import MediaContacts from "../routes/MediaContacts";

const NullComponent = () => <></>;

const StyledContainer = styled(Container)`
  & > .row {
    min-height: 100vh;
  }
  .nav-container {
    @media print {
      display: none;
    }
    background-color: ${colors.techNavGreen.hex};
    padding: 14vh 3rem;
    a {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .header-text {
    text-align: center;
    padding-bottom: 2rem;
  }
  .body-text {
    text-align: center;
    padding: 0 20%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    .nav-container {
      padding: 3vh 1.5rem;
      height: fit-content;
    }
  }
`;

const registeredCalculators = {
  "thrust-restraint-calculator": CalcThrustRestraint,
  "design-on-supports": CalcSupports,
  "hydraulic-analysis-and-greenhouse-gas-emissions": CalcHydraulic,
  "thickness-design": CalcThicknessDesign,
  "life-cycle-cost-analysis": CalcLcca
};

const pathHashTable = {
  "/media-newsroom/news-and-commentary": NewsAndCommentaryPageV2,
  "/media-newsroom/e-newsletters": ENewslettersNewsPage,
  "/media-newsroom/media-contacts": MediaContacts,
  // "/media-newsroom/dipra-resources/image-librarydipra-staff": StaffNewsroomComp,
  "/media-newsroom/dipra-resourcesfact-sheets": () => {
    return (
      <>
        <DocumentTable isNewsroomPage />
      </>
    );
  }
};

/**
 *
 * @param {Object} pageContext
 * @param {Object} data
 * @returns {JSX}
 */
const ResourceNode = ({
  pageContext,
  data
}: {
  pageContext: {
    parentPath: string;
    slug: string;
    technicalResourceOrNewsroomPage: string;
  };
  data: ResourceNodeQuery;
}) => {
  const resource = new Resource(data.resource);
  // console.log("pageContext", pageContext);
  // console.log("data", data);
  const techMenus = data.techMenus.edges.map((e) => e.node);
  const newsroomMenus = data.newsroomMenus.edges.map((e) => e.node);
  let menuParentSlug;
  const currentMenu =
    // pageContext?.technicalResourceOrNewsroomPage === "Newsroom Page" &&
    // pageContext?.parentPath === "/"
    //   ? data.newsroomTopLevelMenu
    //   :
    pageContext?.technicalResourceOrNewsroomPage === "Newsroom Page"
      ? newsroomMenus
      : techMenus;
  const thisSlug =
    pageContext?.technicalResourceOrNewsroomPage === "Newsroom Page" &&
    pageContext?.slug &&
    pageContext?.slug === "/"
      ? "media-newsroom"
      : pageContext?.slug;
  const menu = currentMenu.reduce((match, m) => {
    // console.log("m", m);
    // console.log("m.menuParent", m.menuParent);
    // console.log("m.menuParent?.resourceSlug", m.menuParent?.resourceSlug)
    const parentSlug = m.menuParent?.resourceSlug
      ? m.menuParent?.resourceSlug
      : m.menuParent?.pageSlug;
    // console.log("parentSlug", parentSlug);
    // console.log("thisSlug", thisSlug);
    const nearest = resource.hierarchy.nearest().slug;
    // console.log("resource.hierarchy", resource.hierarchy);
    // console.log("nearest", nearest);
    // console.log("parentSlug === thisSlug", parentSlug === thisSlug);
    // console.log("parentSlug === nearest", parentSlug === nearest);

    if (parentSlug === thisSlug) {
      menuParentSlug = parentSlug;
      const childLinks = m.links.length ? m.links : [];
      // console.log("childLinks1", childLinks);
      return [...childLinks];
    } else {
      if (parentSlug === nearest) {
        menuParentSlug = parentSlug;
        const childLinks = m.links.length ? m.links : [];
        // console.log("childLinks2", childLinks);
        return [...childLinks];
      }
    }
    return match;
  }, []);
  // console.log("menu", menu);
  const parent = resource?.parent;
  const path = resource?.parentPath;
  const slug = resource?.slug;
  const isCalc = parent === `/calculators`;
  const CalcJsx = isCalc ? registeredCalculators[`${slug}`] : NullComponent;

  const isParentPage = path === "/media-newsroom";
  // console.log("path", path);
  const route = `${!isParentPage ? path : ""}${slug}`;
  const RouteComponent: React.FunctionComponent =
    route in pathHashTable ? pathHashTable[route] : () => <></>;
  // console.log("route", route);
  const RouteCompJSX = RouteComponent ? <RouteComponent /> : null;
  return (
    <>
      <WmkSeo.Meta
        title={resource.serpTitle ? resource.serpTitle : resource.title}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug={
          resource?.parentPath === "/technical-resources/" || resource?.parentPath === "/media-newsroom/" ? slug : `/${slug}`
        }
        path={resource?.parentPath ? resource.parentPath : ""}
        description={resource.meta}
        ogImageUrl={resource.ogImage ? resource.ogImage : ""}
        twitterImageUrl={resource.twitterImage ? resource.twitterImage : ""}
        twitterHandle={data.site.siteMetadata.twitter}
      />
      <StyledContainer fluid>
        <Row style={{}}>
          <Col className="nav-container" xs={12} sm={12} md={5} lg={4}>
            <TechResNav
              resource={resource ? resource : undefined}
              menu={menu}
              slug={pageContext.slug}
              isNewsroomPage={
                pageContext?.technicalResourceOrNewsroomPage === "Newsroom Page"
                  ? true
                  : false
              }
            />
          </Col>
          <Col xs={12} sm={12} md={7} lg={8}>
            {/* content-target div used to snap page down to the content when a resource nav item is clicked on mobile */}
            <div id="content-target" />
            <DipraBreadcrumbs route={`${path}/${slug}`} />
            {slug === "testimonials" ? (
              <PageHeader
                headline={`Testimonials`}
                // subhead={`DIPRA keeps you up-to-date on issues important to the water industry.`}
                subtext={`Don’t take DIPRA’s word that Ductile iron pipe is the superior material in the world today, here from industry leaders you have experienced all of the benefits of Ductile iron pipe in your community’s water infrastructure.`}
              />
            ) : (
              <></>
            )}
            {isCalc ? <CalcJsx /> : <TechResContent resource={resource} />}
            {RouteCompJSX && slug === "news-and-commentary" ? (
              <>
                <PageHeader
                  headline={`Water Industry News, Opinion and Commentary`}
                  // subhead={`DIPRA keeps you up-to-date on issues important to the water industry.`}
                  // subtext={`DIPRA keeps you up-to-date on issues important to the water industry.`}
                />
                <Container style={{ padding: "0 2vw" }}>
                  <Row>
                    <Col>
                      {RouteCompJSX}
                      <Type.H3 className="header-text">Commentary</Type.H3>
                      <Type.Body className="body-text">{`Welcome to the DIPRA Commentary. This section will discuss various topics related to the ductile iron pipe industry. From the latest advancements in manufacturing to the benefits of using Ductile iron pipe in various applications, we aim to provide valuable insights and analysis for anyone in the field. Our goal is to keep you informed about the latest developments in the industry. So, whether you are a seasoned professional or just starting out, we hope you find this series informative and engaging.`}</Type.Body>
                      <DipraNewsSlider filter="Commentary"/>
                      <Type.Body className="body-text">
                        {`DIPRA keeps you up-to-date on issues important to the water industry.`}
                      </Type.Body>
                      <JoinMailingListButton copy="Subscribe to our E-Newsletters" />
                      <Type.H3 className="header-text" style={{paddingTop: "2rem"}}>Upcoming Events</Type.H3>
                      <Type.Body className="body-text">{`DIPRA sponsors, exhibits, and speaks at many national and local conferences in the water sector. At many of these event, our regional engineers will be available to help you with your pipeline application questions and specification of Ductile iron pipe. `}</Type.Body>
                      <NewsroomEventsList />
                      <Type.Body
                        className="body-text"
                        style={{ margin: "-2rem 0 6rem" }}>
                        Find out{" "}
                        <WmkLink to="/ask-an-expert/where-dipra-will-be">
                          here
                        </WmkLink>{" "}
                        where DIPRA engineers will be. 
                      </Type.Body>
                    </Col>
                  </Row>
                </Container>
              </>
            ) : RouteCompJSX && slug === "dipra-overview" ? ( 
              <>
              {RouteCompJSX}
              <Container style={{ padding: "0 2vw" }}>
                  <Row>
                    <Col>
              <MemberCompaniesContent onlyLogos/>
                    </Col></Row></Container>
              </>
            ) : RouteCompJSX ? (
              <>{RouteCompJSX}</>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </StyledContainer>
      <FixedNav parentSlug={menuParentSlug ? menuParentSlug : thisSlug}>
        <TechButtons menu={menu} slug={pageContext.slug} />
      </FixedNav>
    </>
  );
};

export default ResourceNode;

export interface ResourceNodeQuery {
  resource: ResourceQuery;
  techMenus: {
    edges: {
      node: RolloverMenuFields;
    }[];
  };
  newsroomMenus: {
    edges: {
      node: RolloverMenuFields;
    }[];
  };
  newsroomTopLevelMenu: RolloverMenuFields;
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
}

export const query = graphql`
  query resQuery($slug: String) {
    resource: contentfulResource(slug: { eq: $slug }) {
      ...NodeResourceFields
      ...ResourceBlockIconCopy
      ...ResourceBlockImageCopy
      ...ResourceAsset
      ...ResourceBlockMediaAssetsGallery
    }
    techMenus: allContentfulMenu(
      filter: { title: { regex: "/technical resources >/i" } }
    ) {
      edges {
        node {
          ...NodeMenuFields
        }
      }
    }
    newsroomMenus: allContentfulMenu(
      filter: { title: { regex: "/media newsroom/i" } }
    ) {
      edges {
        node {
          ...NodeMenuFields
        }
      }
    }
    newsroomTopLevelMenu: contentfulMenu(title: { eq: "Media Newsroom Menu" }) {
      ...NodeMenuFields
    }
    site {
      siteMetadata {
        baseUrl
        title
        twitter
      }
    }
  }
`;
