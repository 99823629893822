import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import { EventQuery } from "../../../fragments/NodeEventFields";
import { Type } from "../Typography";
import EventList from "./Events";


const NewsroomEventsList = () => {
  const data: {
    events: { edges: EventQuery[] };
  } = useStaticQuery(graphql`
    query {
      events: allContentfulEvents(sort: { fields: startDate, order: ASC }) {
        edges {
          node {
            ...NodeEventFields
          }
        }
      }
    }
  `);

return (
  <>
    <Container style={{marginTop: "2rem"}}>
      <Row>
        {/* <Col
          style={{
            position: "relative",
            top: "10px",
            display: "flex",
            justifyContent: "center"
          }}>
          <Type.SubHeader2>{`UPCOMING EVENTS`}</Type.SubHeader2>
          <Type.Body>{`DIPRA sponsors, exhibits, and speaks at many national and local conferences in the water sector. At many of these event, our regional engineers will be available to help you with your pipeline application questions and specification of Ductile iron pipe. `}</Type.Body>
        </Col> */}
      </Row>
    </Container>
    <EventList data={data} />
  </>
)
}

export default NewsroomEventsList