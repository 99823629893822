import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";
import { Type } from "../../components/ui/Typography";

const Wrap = styled.div`
  margin-bottom: 4rem;
  h2,
  h3,
  p {
    text-align: center;
    max-width: 800px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .hidden {
    opacity: 0;
    height: 0;
    position: absolute;
    top: -100vh;
  }
  padding: 0 3rem;
`;

const MediaContacts = () => {
  return (
    <Container>
      <Wrap>
        <Type.CardHeadline>{`For all media inquiries please complete the form below.`}</Type.CardHeadline>
        <HubspotForm
          portalId="21124615"
          formId="de1eea68-4f24-42ee-b696-6a8cb9790d92"
          // onSubmit={() => console.log('Submitted!')}
          // onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
      </Wrap>
    </Container>
  );
};

export default MediaContacts;
